html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

* {
  font-family: Helvetica,Arial,sans,sans-serif;
}
