#bar, #map {
  margin: 0;
  position: absolute;
  top: 0;
  bottom: 0;
}

#bar {
  padding: 10px;
  left: 0;
  width: 300px;
}

#map {
  padding: 0;
  left: 320px;
  right: 0;
}

.hint {
  font-style: italic;
  font-size: 85%;
}

#legend {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 140px;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 1px 5px rgba(0,0,0,0.4);
}

#legend .header {
  text-align: center;
  font-weight: bold;
}

#legend .subhead {
  text-align: center;
  font-size: 85%;
}

.legtable td {
  font-size: 85%;
}

.legtable .col {
  width: 20px;
  opacity: 0.6;
}

.legtable .from, .legtable .to {
  text-align: right;
}

.legtable td {
  padding-left: 0.3em;
}

.legtable tr td:first-child {
  padding-left: 0;
}

.leaflet-control-permalink {
  display: none;
}

.placename {
  font-size: 150%;
  font-weight: bold;
}

.placetype {
  font-style: italic;
}

table.geninfo {
  padding-top: 1em;
}

table.geninfo tr td {
  padding-left: 0.3em;
}

table.geninfo tr td:first-child {
  padding-left: 0;
}

table.geninfo td.num {
  text-align: right;
}
